import React, { useState, useRef } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import BrandPB from '../../images/assets/brand-with-pb.png';
import PBTshirt from '../../images/assets/punkbrandz-tshirt.png';
import Footer from '../Footer/Footer';


// interface IFormInput {
//     firstName: string
//     lastName: string
//     email: string
//     subject: string
//     phoneNumber: string
//     message: string
// }

const serviceId: string = process.env.REACT_APP_SERVICE_ID!;
const templateId: string = process.env.REACT_APP_TEMPLATE_ID!;

const ContactUs = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [stateMessage, setStateMessage] = useState<null | string>('');
    const form = useRef<HTMLFormElement>(null);
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>();

    const sendEmail = (data: any) => {
        const email = form.current ?? 'default';
        emailjs
            .sendForm(
                serviceId,
                templateId,
                email,
                process.env.REACT_APP_PUBLIC_KEY
            )
            .then((result) => {
                console.log('sent email: ', result);
                setStateMessage('Thank you for your message. We will contact you shortly!');
                setTimeout(() => {
                    setStateMessage(null)
                }, 5000)
            })
            .catch(err => {
                console.log('error sending email: ', err);
                setStateMessage('Something went wrong, please try again');
                setTimeout(() => {
                    setStateMessage(null);
                }, 5000)
            })
            setPhoneNumber('');
            reset();
    };


    const formatPhoneNumber = (e: any) => {
        let formattedNumber;
        const { value } = e.target;
        const { length } = value;
        // Filter non numbers
        const regex = () => value.replace(/[^0-9.]+/g, "");
        // Set area code with parenthesis around it
        const areaCode = () => `(${regex().slice(0, 3)})`;
        
        // Set formatting for first six digits
        const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;
        
        // Dynamic trail as user types
        const trailer = (start: any) => `${regex().slice(start,   
        regex().length)}`;
          if (length < 3) {
             // First 3 digits
             formattedNumber = regex();
          } else if (length === 4) {
             // After area code
             formattedNumber = `${areaCode()} ${trailer(3)}`;
          } else if (length === 5) {
             // When deleting digits inside parenthesis
             formattedNumber = `${areaCode().replace(")", "")}`;
          } else if (length > 5 && length < 9) {
             // Before dash
             formattedNumber = `${areaCode()} ${trailer(3)}`;
          } else if (length >= 10) {
             // After dash
             formattedNumber = `${firstSix()}-${trailer(6)}`;
          }

        setPhoneNumber(formattedNumber);
      };


    return (
        <div className='bg-secondary p-5 mw-100'>
            <Row>
                <Col className='mt-5 ps-5 pe-5 pt-5 pb-0 mb-0'>
                    <h1 className='font-primary text-primary' style={{ fontSize: "10rem" }} >CONTACT</h1>
                </Col>
            </Row>
            <Row>
                <Col className='mt-0 p-5 mb-5'>
                    <form ref={form} onSubmit={handleSubmit(sendEmail)} >
                        <Row className='m-0 p-0'>
                            <Col className='m-0 p-0 col-md-6 ps-0 pe-1'>
                            <Row className='m-0 p-0'>
                                    <label className='text-dark ps-0 font-primary'>FIRST NAME</label>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <input type='text' className='text-primary font-body input-text' 
                                        {...register("firstName", { required: true, maxLength: 20 })} 
                                        aria-invalid={errors.firstName ? "true" : "false"}
                                    />
                                </Row>
                                <Row>
                                    {errors.firstName?.type === "required" && (
                                        <p role="alert">First name is required.</p>
                                    )}
                                </Row>
                            </Col>
                            <Col className='m-0 p-0 col-md-6 ps-1 pe-0'>
                                <Row className='m-0 p-0'>
                                    <label className='text-dark ps-0 font-primary'>LAST NAME</label>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <input type="text" className='text-primary font-body input-text' 
                                        {...register("lastName", { required: true, maxLength: 20 })} 
                                        aria-invalid={errors.lastName ? "true" : "false"}
                                    />
                                </Row>
                                <Row>
                                    {errors.lastName?.type === "required" && (
                                        <p role="alert">Last name is required.</p>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Row className='p-0 mt-3 ms-0 me-0 mw-100'>
                            <Col className='col-md-12 m-0 p-0'>
                                <Row className='m-0 p-0'>
                                    <label className='text-dark ps-0 font-primary'>EMAIL</label>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <input type='email' className='text-primary font-body input-text' 
                                        {...register("email", { required: true, maxLength: 30, pattern:  /^[^@\s]+@[^@\s]+\.[^@\s]+$/ })} 
                                        aria-invalid={errors.email ? 'true' : 'false'}
                                    />
                                </Row>
                                <Row>
                                    {errors.email?.type === "required" && (
                                        <p role="alert">Email is required.</p>
                                    )}
                                    {errors.email?.type === "pattern" && (
                                        <p role='alert'>Email format is invalid.</p>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Row className='p-0 mt-3 ms-0 me-0 mw-100'>
                            <Col className='col-md-12 m-0 p-0'>
                                <Row className='m-0 p-0'>
                                    <label className='text-dark ps-0 font-primary'>PHONE NUMBER (optional)</label>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <input className='text-primary font-body input-text' 
                                        {...register("phoneNumber", { required: false, maxLength: 14, pattern: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/ })} 
                                        aria-invalid={errors.phoneNumber ? 'true' : 'false'}
                                        value={phoneNumber}
                                        onChange={formatPhoneNumber}

                                    /> 
                                </Row>
                                <Row>
                                    {errors.phoneNumber?.type === "pattern" && (
                                        <p role="alert">Phone number must only include numbers.</p>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Row className='p-0 mt-3 ms-0 me-0 mw-100'>
                            <Col className='col-md-12 m-0 p-0'>
                                <Row className='m-0 p-0'>
                                    <label className='text-dark ps-0 font-primary'>SUBJECT</label>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <input className='text-primary font-body input-text' 
                                        {...register("subject", { required: true, maxLength: 30 })} 
                                        aria-invalid={errors.subject ? 'true' : 'false'}
                                    />
                                </Row>
                                <Row>
                                    {errors.subject?.type === "required" && (
                                        <p role="alert">Subject is required.</p>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Row className='p-0 mt-1 mt-3 ms-0 me-0 mw-100 mb-2'>
                            <Col className='col-md-12 m-0 p-0'>
                                <Row className='m-0 p-0'>
                                    <label className='text-dark ps-0 font-primary'>MESSAGE</label>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <textarea className='text-primary font-body input-text' 
                                        {...register("message", { required: true, maxLength: 255 })}
                                        aria-invalid={errors.message ? 'true' : 'false'} 
                                    />
                                </Row>
                                <Row>
                                    {errors.message?.type === "required" && (
                                        <p role="alert">Message is required.</p>
                                    )}
                                    {errors.message?.type === "maxLength" && (
                                        <p role="alert">Message can not be over 255 characters.</p>
                                    )}
                                </Row>
                                <Row className='text-center mt-3'>
                                    {stateMessage && (<h2 className='font-primary text-primary'>{stateMessage}</h2>)}
                                </Row>
                            </Col>
                        </Row>
                        <Row className='p-0 text-center mt-5'>
                            <Col>
                                <button type='submit' className='btn-main'>BRAND ME</button>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
            <Row className='ps-5 pe-5 pt-3 pb-3 ms-0 me-0 mt-0 mb-3' style={{borderTop: "2px dashed #FF00B8", borderBottom: "2px dashed #FF00B8" }}>
                <Col className='col-md-7 text-center position-relative'>
                    <Image className='vertical-center' style={{ maxWidth: "110%"}} src={BrandPB} alt="Brand with PunkBrands" />
                </Col>
                <Col className='col-md-5 text-center'>
                    <Image className='mw-100' src={PBTshirt} alt="PunkBrands t-shirt" />
                </Col>
            </Row>
            <Row className='p-2'></Row>
        </div>
    )
};

export default ContactUs;