import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav,  Image } from 'react-bootstrap';
import Logo from '../../images/assets/Punkbrandz-Logo.svg';


const NavBar = () => {
    const location = useLocation();
    const pathname = location.pathname;

    const highlightLink = (location: string, link: string) => {
        if (link === location) {
            return "underline"
        } else {
            return "none"
        }
    };

    const changeNavBackground = (location: string) => {
        switch (location) {
            case '/':
                return "transparent";
            case '/contact':
                return "#eeeeee";
            default:
                return "transparent";    

        }
    };

    const changeLinkColor = (location: string, link: string) => {
        if (location !== link) {
            switch (location) {
                case '/':
                    return "#eeeeee";
                case '/contact':
                    return "#101519";
                default:
                    return "#eeeeee"
            }
        } else {
            return "#FF00B8";
        }
    }

    return (
        <>
        <Navbar className="m-0 pr-0 pl-0 pt-3 main-nav" style={{ maxWidth: "100%", backgroundColor: changeNavBackground(pathname), zIndex: "6" }}>
            <Navbar.Brand as={Link} to='/' className='pb-2 ml-2'>
                <Image className='nav-logo ms-5' src={Logo} alt="PunkBrands Logo" />
            </Navbar.Brand>
            <Navbar.Collapse className='justify-content-end'>
                <Nav className='mw-100'>
                    <Nav.Link className={"nav-font me-5 text-center"} as={Link} to='/work' style={{ textDecoration: highlightLink(pathname, '/work'), color: changeLinkColor(pathname, '/work') }}>
                        WORK
                    </Nav.Link>
                    <Nav.Link className={"nav-font me-5 text-center"} as={Link} to='/services' style={{ textDecoration: highlightLink(pathname, '/services'), color: changeLinkColor(pathname, '/services')}}>
                        SERVICES
                    </Nav.Link>
                    <Nav.Link className={"nav-font me-5 text-center"} as={Link} to='/about' style={{ textDecoration: highlightLink(pathname, '/about'), color: changeLinkColor(pathname, '/about')}}>
                        ABOUT US
                    </Nav.Link>
                    <Nav.Link className={"nav-font me-5 text-center"} as={Link} to='/contact'style={{ textDecoration: highlightLink(pathname, '/contact'), color: changeLinkColor(pathname, '/contact')}} >
                        CONTACT
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <Navbar
        className="mobile-nav pe-2 ps-2"
        collapseOnSelect
        expand="full"
        variant="secondary"
        style={{ maxWidth: "100%" }}
        >
        <Navbar.Brand href="#home">
            <Image className='nav-logo' src={Logo} alt="PunkBrands Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='text-secondary bg-secondary' />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto text-secondary">
          <Nav.Link className={"nav-font text-center " + (pathname === '/work' ? 'text-primary' : 'text-secondary')} as={Link} to='/work'>
                        WORK
                    </Nav.Link>
                    <Nav.Link className={"nav-font text-center " + (pathname === '/services' ? 'text-primary' : 'text-secondary')} as={Link} to='/services'>
                        SERVICES
                    </Nav.Link>
                    <Nav.Link className={"nav-font text-center " + (pathname === '/about' ? 'text-primary' : 'text-secondary')} as={Link} to='/about'>
                        ABOUT US
                    </Nav.Link>
                    <Nav.Link className={"nav-font text-center " + (pathname === '/contact' ? 'text-primary' : 'text-secondary')} as={Link} to='/contact' >
                        CONTACT
                    </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
        </>
    );
};

export default NavBar;