import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import HomeVideo from '../../images/assets/pb_home_video_3.mp4';
// import { SequenceAnimator } from 'react-sequence-animator';
// import StrategizeAnimation from "./StrategizeAnimation";
import StrategizeGif1 from '../../images/assets/WS_Test_3.gif';
import StrategizeGif3 from '../../images/assets/WS_Test_5.gif';
import MarketGif from '../../images/assets/WM_Test_7.gif';
import Four33Logo from '../../images/client-logos/433.png';
import XeroxLogo from '../../images/client-logos/xerox.png';
import ScopelyLogo from '../../images/client-logos/Scopley.png';
import BMWLogo from '../../images/client-logos/BMW.png';
import MercuryLogo from '../../images/client-logos/Merc.png';
import GorillaLogo from '../../images/client-logos/GFF.png';
import PubGLogo from '../../images/client-logos/pubg.png';
import YokohamaLogo from '../../images/client-logos/Yokohama.png';
import BHLogo from '../../images/client-logos/BH.png';
import { useNavigate } from "react-router-dom";
import ContactUs from "./ContactUsSection";
import CookieConsent from 'react-cookie-consent';

// const weStrategizeImages = require.context('../../images/we-strategize');
// const weStrategizeImageList = weStrategizeImages.keys().map(image => weStrategizeImages(image));


const HomePage = () => {
    const navigate = useNavigate();

    const navigateToServices = () => {
        navigate('/services');
    }


    return (
        <div className="mw-100 w-100 m-0 p-0">
            <Row className="mw-100" style={{ marginTop: "-10rem" }}>
                <Col>
                <video style={{ width: "100vw", height: "auto" }} autoPlay muted loop >
                    <source src={HomeVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </Col>
            </Row>
            <Row className="mt-5 mb-5 mw-100">
                <Col className="col-md-10 offset-md-1">
                    <p className="text-secondary font-primary text-center" style={{ fontSize: "1.9rem" }}>We defy convention and propel your brand to new heights of distinction. Through collaborative efforts and unwavering dedication, we craft experiences that resonate deeply with your audience. With <span className="text-primary">Punk Brands</span> by your side, expect nothing less than standout creativity and unparalleled innovation in every project we undertake.</p>
                </Col>
            </Row>
            <Row className="ps-5 pe-5 mw-100 pt-5 pb-5">
                <Col className="col-md-6 position-relative">
                    <div className="home-service-container me-5">
                        <h1 className="text-primary we-headings mb-0 pb-0"><span className="text-secondary">WE</span> STRATEGIZE</h1>
                        <p className="body-font text-secondary" style={{ marginTop: "-1em" }}>At PunkBrandz, our branding strategy is where the magic begins. We delve deep into understanding our clients' unique identity, values, and goals, crafting strategies that set them apart in their industry. Through collaborative sessions and thorough research, we create experiences that captivate, inspire, and drive meaningful connections with their target audience.</p>
                        <p className="body-font text-primary" style={{ textDecoration: "underline" }}>LEARN MORE</p>
                    </div>
                </Col>
                <Col className="col-md-6">
                    <Image src={StrategizeGif1} style={{ cursor: "pointer" }} onClick={navigateToServices} />
                </Col>
            </Row>
            <Row className="ps-5 pe-5 mw-100 pt-5 pb-5">
                <Col className="col-md-6">
                    <Image src={StrategizeGif3} style={{ cursor: "pointer" }} onClick={navigateToServices} />
                </Col>
                <Col className="col-md-6 position-relative text-end">
                    <div className="home-service-container">
                        <h1 className="text-primary we-headings mb-0 pb-0"><span className="text-secondary">WE</span> BRAND</h1>
                        <p className="body-font text-secondary" style={{ marginTop: "-1em" }}>Our branding process is anything but ordinary. We delve deep into understanding your brand essence, values, and aspirations, crafting identities that resonate with your audience on a profound level. What sets us apart is our fearless approach to creativity, infusing each project with our signature style that pushes boundaries and defies conventions. We believe in standing out rather than blending in, ensuring that your brand leaves a lasting impression in a crowded market landscape. With PunkBrandz, expect nothing short of bold, innovative, and unforgettable branding experiences.</p>
                        <p className="body-font text-primary" style={{ textDecoration: "underline" }}>LEARN MORE</p>
                    </div>
                </Col>
            </Row>
            <Row className="ps-5 pe-5 mw-100 pt-5 pb-3">
            <Col className="col-md-6 position-relative">
                    <div className="home-service-container me-5">
                        <h1 className="text-primary we-headings"><span className="text-secondary">WE</span> MARKET</h1>
                        <p className="body-font text-secondary" style={{ marginTop: "-1em" }}>This is where your finished product truly comes to life. With a meticulous blend of creativity and strategy, we take your brand and showcase it to the world in ways that captivate, engage, and inspire. Through targeted campaigns, compelling storytelling, and innovative marketing channels, we ensure that your brand message reaches the right audience at the right time. From digital advertising to experiential marketing, we leverage a diverse array of platforms to amplify your brand's presence and drive meaningful connections with your customers. At PunkBrandz, our mission is not just to market your product, but to elevate it into a cultural phenomenon that leaves a lasting impact on the market.</p>
                        <p className="body-font text-primary" style={{ textDecoration: "underline" }}>LEARN MORE</p>
                    </div>
                </Col>
                <Col className="col-md-6">
                    <Image src={MarketGif} style={{ cursor: "pointer" }} onClick={navigateToServices} />
                </Col>
            </Row>
            <Row className="pt-1 ps-5 pb-5 mb-5 mw-100">
                <Col className="col-md-6">
                    <button className="btn-main" onClick={() => navigate('/work')} >OUR WORK!</button>
                </Col>
            </Row>
            <Row className="pt-5 ps-5 pb-0 mb-0 mw-100">
                <Col className="col-md-12 text-left">
                    <h1 className="font-primary text-secondary">OUR CLIENTS</h1>
                </Col>
            </Row>
            <Row className="ps-5 pe-5 pt-0 pb-5 mw-100">
                <Col className="col-md-4 text-center">
                    <Image src={Four33Logo} style={{ height: "15rem"}} />
                    <Image className="m-0 p-0" src={XeroxLogo} style={{ height: "15rem"}} />
                    <Image className="m-0 p-0" src={ScopelyLogo} style={{ height: "15rem"}} />
                </Col>
                <Col className="col-md-4 text-center">
                    <Image src={BMWLogo} style={{ height: "15rem"}} />
                    <Image className="m-0 p-0" src={MercuryLogo} style={{ height: "15rem"}} />
                    <Image className="m-0 p-0" src={GorillaLogo} style={{ height: "15rem"}} />
                </Col>
                <Col className="col-md-4 text-center">
                    <Image src={PubGLogo} style={{ height: "15rem"}} />
                    <Image className="m-0 p-0" src={YokohamaLogo} style={{ height: "15rem"}} />
                    <Image className="m-0 p-0" src={BHLogo} style={{ height: "15rem"}} />
                </Col>
            </Row>
            <ContactUs />
            <CookieConsent
                location="bottom"
                buttonText="Agree"
                style={{ background: "#101519" }}
                buttonStyle={{ background: "FF00B8", fontSize: "1.5rem" }}
            >
                This website user cookies to enhance the user experience.
            </CookieConsent>
        </div>
    );
};

export default HomePage;