import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import ContactGif from '../../images/assets/WS_Test_8.gif';
import { useNavigate } from 'react-router-dom';


const ContactUs = () => {
    const navigate = useNavigate();

    const navigateToContact = () => {
        navigate('/contact');
    }
    return (
        <Row className="pt-5 ps-5 pe-5 pb-0 mb-0 mw-100 w-100 bg-secondary ms-0 me-0">
            <Col className="col-md-3 mt-5 position-relative">
                <div className="contact-us-headline-container">
                    <h1 className="text-dark contact-us-headline" >READY TO</h1>
                    <h1 className="text-dark contact-us-headline" style={{ marginTop: "-3rem" }}>STAND OUT?</h1>
                </div>
            </Col>
            <Col className="col-md-3 mt-5">
                <Image src={ContactGif} style={{ cursor: "pointer" }} onClick={navigateToContact} />
            </Col>
        </Row>
    )
};

export default ContactUs;